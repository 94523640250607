
@font-face {
  font-family: 'Medium';
  src: local('SpecialElite'), url(./fonts/SpecialElite-Regular.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


input {
  all: unset;
}

.App {
  text-align: center;
}

.app-row {
  height: calc(100vh / 3);
}

.login img {
  padding: 30% 0;
}

.login img:active {
  color: crim;
}

.cthulu-font {
  color: black;
  font-family: "Medium";
}

.border {
  text-align: center;
  border: 1px solid #222;
  border-radius: 4px;
  justify-content: center;
}

.uk-text-tiny {
  font-size: .75rem;
  line-height: 1.2;
}

.characteristic-pad {
  padding: 0 5px; 
}

.pad-bottom {
  padding-bottom: 5px;
}
/* ------------------------------------------------------------------------- */
/* STATS SECTION */
/* ------------------------------------------------------------------------- */

.stats-section {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  align-content: center;
}

.stats {
  background-color: antiquewhite;
  align-items: center;
}

.stats-row {
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  align-content:stretch;
}

.stats-item {
  flex-basis: content;
  height: 300px;
}

.pad {
  padding: 0 10%;
}

.image-row {
  height: 300px;
}

/* ------------------------------------------------------------------------- */
/* CHARACTERISTICS  */
/* ------------------------------------------------------------------------- */
.characteristics {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: stretch;
}

.characteristics-text {
  display: flex;
  align-items: center;
}

.characteristics > div {
  margin-bottom: .5em;
}

/* ------------------------------------------------------------------------- */
/* ITEM  */
/* ------------------------------------------------------------------------- */
.item { 
  flex-basis: 30%;
}

.item-pad {
  display: flex;
  align-items: center;
}

.side-pad {
  padding: 0px 10px;
}

.little-pad {
  padding: 5px;
}

input.uk-form-width-xsmall {
  width: 40px;
}

.border-none {
  border: none
}
